// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-btn {
    position: relative
}
.link-btn::after {
    position: absolute;
    top: 0px;
    right: -36px;
    height: 16px;
    width: 30px;
    border-radius: 50px;
    background-image: url('https://cloud4c-poc.focal-learning.com/arrow-link.png');
    background-repeat: no-repeat;
    --tw-content: '';
    content: var(--tw-content)
}

`, "",{"version":3,"sources":["webpack://./src/components/BannerSlider/bannerslider.css"],"names":[],"mappings":"AACI;IAAA;AAAgO;AAAhO;IAAA,kBAAgO;IAAhO,QAAgO;IAAhO,YAAgO;IAAhO,YAAgO;IAAhO,WAAgO;IAAhO,mBAAgO;IAAhO,8EAAgO;IAAhO,4BAAgO;IAAhO,gBAAgO;IAAhO;AAAgO","sourcesContent":[".link-btn {\r\n    @apply relative after:content-[''] after:absolute after:w-[30px] after:h-[16px]  after:rounded-[50px] after:top-0 after:-right-[36px] after:bg-no-repeat after:bg-[url('https://cloud4c-poc.focal-learning.com/arrow-link.png')];\r\n   \r\n }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
