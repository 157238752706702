import React from "react";
import { useFetchData } from "../helper/useFetchData";


interface DescriptionNode {
  type: string;
  children: { type: string; text: string }[];
}

interface TextPara {
  Container: string;
  MainClass: string;
  Col1Class: string;
  Col2Class: string;
  Title: string;
  Description: DescriptionNode[];
}

const TitleInlinePara: React.FC = () => {
  const { data, loading, error } = useFetchData("page?populate=*");

//   if (loading) return <div>Loading page...</div>;
//   if (error) return <div>Error loading page data: {error.message}</div>;

  const pageData: TextPara = data?.data?.TextPara;

  if (!pageData) return <div>No data found.</div>;

  return (
    <div className={`${pageData.MainClass}`}>
      <div className={`${pageData.Container} lg:px-[15px] px-[30px]`}>
        <div className={`${pageData.Col1Class}`}>
          <h2 className="lg:text-[38px] text-[28.5px] font-helveticabold font-medium leading-[1.2] tracking-[.3px]">{pageData.Title}</h2>
        </div>
        <div className={`${pageData.Col2Class}`}>
          {pageData.Description.map((desc, index) => (
            <p key={index} className="[&:not(:last-child)]:pb-5">
              {desc.children.map((child) => child.text).join("")}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TitleInlinePara;
