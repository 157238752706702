import React from 'react';
import logo from './logo.svg';
import './App.css';
import TitleInlinePara from './components/TitleInlinePara';
import BannerSlider from './components/BannerSlider';
import ClickReveal from  './components/ClicktoReveal'
function App() {
  return (
    <>
      <BannerSlider/>
      <TitleInlinePara/>
      <ClickReveal/>
    </>
 
  );
}

export default App;
