import React, { useState } from "react";
import { useFetchData } from "../../helper/useFetchData";

interface ClicktoRevealItem {
  id: number;
  heading: string;
  clicktorevealitems: {
    id: number;
    Description: string | null;
    ListItems: string | null; // ListItems might be null
    Cta: string | null;
    ButtonLink: {
        LinkText:  string | null;
        link:  string | null;
        ButtonClass:  string | null;
      }
  };
  image: {
    url: string;
  };
  
}

const ClicktoReveal: React.FC = () => {
  const { data, loading, error } = useFetchData(
    `page?populate=ClicktoReveal&populate=ClicktoReveal.image&populate=ClicktoReveal.clicktorevealitems&populate=ClicktoReveal.clicktorevealitems.ButtonLink`
  );

//   console.log(data);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const handleToggle = (index: number) => {
    setActiveIndex(index); 
  };

  if (loading) return <div>Loading ClicktoReveal...</div>;
  if (error) return <div>Error loading ClicktoReveal: {error.message}</div>;

  const clickToRevealItems: ClicktoRevealItem[] = data?.data?.ClicktoReveal || [];
  if (!Array.isArray(clickToRevealItems) || clickToRevealItems.length === 0) {
    return <div>No ClicktoReveal items available.</div>;
  }

  return (
    <section className="accordion-hori flex gap-3 justify-content-between">
      {clickToRevealItems.map((item, index) => {
        const { clicktorevealitems, image, heading } = item;
        const description = clicktorevealitems?.Description || "No description available";
        const listItems = clicktorevealitems?.ListItems
          ? clicktorevealitems.ListItems.split("\n").filter(Boolean) // Split and remove empty lines
          : [];
          const buttonLink = clicktorevealitems?.ButtonLink;

        return (
          <div
            key={item.id}
            className={`item-main-w w-1/3 bg-gray-200 min-h-[380px] p-[18px] cursor-pointer  flex justify-center  ${activeIndex === index ? "expanded" : "collapsed"}`}
            onClick={() => handleToggle(index)}
          >
            <div className="item-wrap flex flex-col justify-center">
              <div className="title-icon mb-[30px]">
                <img
                  className=""
                  src={image.url}
                  alt={`icon for ${heading}`}
                  width="88"
                  height="88"
                />
              </div>
             
              <h3 className="text-[22px] leading-[33px] min-h-[66px]">{heading}</h3>
            </div>
        
            <div className="item-info text-white h-100 min-h-[380px] bg-brand-2 relative">
              <div className="flex gap-[30px]">
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2">
                  <div className="item-info-con">
                    <h3 className="text-[20px] leading-[25px] md:text-[26px] md:leading-[1.2]">{heading}</h3>
                    <h4 className="text-[20px] leading-[25px] md:text-[22px] md:leading-[1.2]">{description}</h4>
                    <img
                    alt={`icon for ${item.heading}`}
                    width="88"
                    height="88"
                    src={item.image.url}
                  />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2">
                  <div className="item-info-con">
                    <ul>
                      {listItems.length > 0 ? (
                        listItems.map((listItem, idx) => (
                          <li key={idx} className="flex align-items-center">
                            <div className="list-arrow">
                              <img
                                className="lazy"
                                alt="list-arrow"
                                width="21"
                                height="22"
                                src="https://www.cloud4c.com/sites/default/files/inline-images/list-arrow.png"
                              />
                            </div>
                            <div className="list-text">
                              <p>{listItem}</p>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>No list items available</li>
                      )}
                    </ul>
                    <div className="absolute right-0 bottom-[calc(100%-35px)] ">
                {buttonLink ? (
                  <a
                    className={`${buttonLink.ButtonClass || ""} text-base bg-brand-1 px-[30px] pt-[18px] pb-[14px] `}
                    href={buttonLink.link || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {buttonLink.LinkText || "Explore more"}
                  </a>
                ) : (
                  <span className="text-gray-400">No CTA available</span>
                )}
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ClicktoReveal;
