import React from "react";
import Slider from "react-slick";
import { useFetchData } from "../../helper/useFetchData";
import "slick-carousel/slick/slick.css";
import './bannerslider.css';
interface Banner {
  DesktopBanner: { url: string };
  MobileBanner: { url: string };
  SlideTitle: string;
  SlideDescription: { type: string; children: { type: string; text: string }[] }[];
  ButtonLink: string;
}

// Custom Next Arrow
const NextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <button className="slick-next absolute bg-black md:w-[122px] md:h-[103px] w-[55px] h-[45px] md:left-[122px] left-[55px]  bottom-[10px] " onClick={onClick} aria-label="Next"><img className="rotate-180 m-auto md:w-auto w-[20px]" src="../../slider-arr.png" alt="arrow-left" /></button>
);

// Custom Prev Arrow
const PrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <button className="slick-prev absolute md:w-[122px] md:h-[103px] w-[55px] h-[45px] left-[0px] z-10 bg-brand-1 bottom-[10px]" onClick={onClick} aria-label="Previous"><img className="m-auto md:w-auto w-[20px]" src="../../slider-arr.png" alt="arrow-left" /></button>
);

const BannerSlider: React.FC = () => {
  const { data, loading, error } = useFetchData(
    "page?populate[0]=BannerSlider&populate[1]=BannerSlider.MobileBanner&populate[2]=BannerSlider.DesktopBanner"
  );

  if (loading) return <div>Loading banners...</div>;
  if (error) return <div>Error loading banners: {error.message}</div>;

  const banners = data?.data?.BannerSlider; // Adjust data path
  // console.log("Banners:", banners);

  if (!Array.isArray(banners) || banners.length === 0) {
    return <div>No banners available.</div>;
  }

  // Slick slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };



  return (
    <Slider {...sliderSettings} className="banner-slider ">
      {banners.map((banner: Banner, index: number) => (
        <div key={index} className="banner-slide relative">
          <picture>
            <source media="(max-width: 768px)" srcSet={banner.MobileBanner?.url} />
            <source media="(min-width: 769px)" srcSet={banner.MobileBanner?.url} />
            <img
              src={banner.MobileBanner?.url}
              // src={`${baseUrl}${banner.MobileBanner?.url}`}
              alt={banner.SlideTitle}
              className="banner-image object-cover h-[500px] md:h-[100vh] w-[100%]"
            />
          </picture>
        
          <div className="banner-content absolute top-[20%] w-full">
            <div className="max-w-1170px-container mx-auto text-left lg:px-[15px] px-[30px] text-white">
            <h3 className="slide-title lg:text-[38px] text-[28.5px] font-helveticabold font-medium leading-[1.2] tracking-[.3px] pb-[10px]">{banner.SlideTitle}</h3>
        
            <p className="slide-description lg:text-[21px] text-[16px] font-light lg:leading-10 leading-6 pb-[50px]">
              {banner.SlideDescription.map((desc) =>
                desc.children.map((child) => child.text).join("")
              )}
            </p>
            
            <div className="relative ml-3 before:content-[''] before:absolute before:w-[50px] before:h-[50px] before:bg-black before:rounded-[50px] before:-top-[12px] before:-left-[12px]" dangerouslySetInnerHTML={{ __html: banner.ButtonLink }} />
            
            </div>
           
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default BannerSlider;

